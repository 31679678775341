$ = jQuery;

var $log = function(msg) {
        if (App.debug) {
            if (arguments.length > 1) {
                var to_console = [];
                for (i = 1; i < arguments.length; i++) {
                    to_console[i - 1] = arguments[i][0];
                }
                console.log(msg, to_console);
            } else {
                console.log(msg);
            }
        }
    },
    App = {
        debug: true,
        init: function() {

            this.scripts();

        },
        loaded: function() {},
        scripts: function() {

            if (App.is_touch_device()) {
                $("body").addClass("touch");
            } else {
                $("body").addClass("no-touch");
            }

            var $win = $(window),
                $body = $("body"),
                scrollPos = $win.scrollTop();

            $win.on('scroll', function() {

                if ($(this).scrollTop() >= 50) {
                    $('body').addClass('page-scrolled');
                } else {
                    $('body').removeClass('page-scrolled');
                }

                scrollPos = $win.scrollTop();

            });

            // To top
            $(".back-top").on("click", function() {
                $("html,body").animate({
                    scrollTop: 0
                }, 550);
            });

            // Content link
            $(".content-link").click(function(e) {
                if ($(this).attr('data-slug').length && (typeof $(this).data('slug') !== 'undefined')) {
                    e.preventDefault();
                    var id = $(this).attr('data-slug');
                    $('html,body').animate({
                        scrollTop: $('#' + id).offset().top - 50
                    }, 550);
                }
            });

        },

        sliders: function() {

        },

        mobMenu: function(menuWrapper) {

        },
        is_touch_device: function() {
            return (("ontouchstart" in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
        }

    };

$(document).ready(function() {
    App.init();
    App.mobMenu($(".page-header"));
});